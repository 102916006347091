const BoConfig = {
    sidebar: [
        {name:"Dashboard",link:"BoDashboard",icon:"fas fa-tachometer-alt"},
        {name:"Pages",divider:'Pages',icon:'',link:''},
        // {name:"Main Website", icon:"fas fa-home",childs:[
            
        // ]},
        {name:"Home",link:"SnkHome",icon:"fas fa-home"},
        {name:"About",link:"SnkAbout",icon:"fas fa-book"},
        {name:"News",link:"SnkNews",icon:"fas fa-newspaper"},
        {name:"Career",link:"SnkCareer",icon:"fas fa-file"},
        {name:"Contact",link:"SnkContact",icon:"fas fa-envelope"},
        {name:"Partner",link:"SnkPartner",icon:"fas fa-users"},
        // {name:"Projects", link:"ProjectHome", icon:"fas fa-building"},
        {name:"Projects",link:"MrProject",icon:"fas fa-building"},
        {name:"Contact Form",link:"Contact",icon:"fas fa-address-book"},

        {name:"Settings",divider:'Settings',icon:'',link:''},
        {name:"Settings", icon:"fas fa-cog",childs:[
            {name:"SEO",link:"SettingSeo"},
            {name:"Banner",link:"Banner"},
        ]},
        {name:"Legal Page",link:"SettingLegalPage",icon:"fas fa-newspaper"},
        {name:"User Management", icon:"fas fa-users",childs:[
            {name:"Users",link:"BoUsers"},
            {name:"User Level",link:"BoUserLevel"},
        ]},
    ],
}
export default BoConfig